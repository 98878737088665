<template>
  <div class="loanDetail">
    <div @click="apply" class="box">
      <h3>兴村通公众号上线啦!</h3>
      <span>2024年10月13日</span>
    </div>
  </div>
</template>

<!-- <script
   type="text/javascript"
   src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"
 ></script> -->
<script>
import Vue from "vue";
import { DetailsFinancialProducts, repayType } from "@/api/RuralFinance";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  name: "xctfllist",
  data() {
    return {
      param1: "",
      param2: "",
      obj: {},
      propsList: [],
      identityObj: {},
      identityObjList: [],
    };
  },
  mounted() {
    //隐藏头部和底部
    const header = document.querySelector(".header"); // 修改选择器以匹配你的需要
    // const outerElement = header.parentElement;
    // const outerClassName = outerElement.className;
    const noShow = document.querySelector(".noShow");
    const homeManage = document.querySelector(".home-manage");
    const footer = document.querySelector(".footer");

    header.style = "display:none";
    footer.style = "display:none";
    noShow.style = "display:none";
    homeManage.style = "padding-top:0";
    this.initParams();
    if (typeof wx !== "undefined" && wx.miniProgram) {
      this.initWx();
    }
  },
  methods: {
    initParams() {
      const queryParams = this.$route.query;
      console.log(queryParams);

      this.obj = queryParams;
      this.param1 = JSON.parse(queryParams.financeProductId);
      this.identityObj = queryParams.identityObj;
      this.identityObjList = this.$route.query;
      console.log(this.param1);
      // console.log(this.param2);
      this.onLoad(this.param1);
    },
    initWx() {
      wx.config({
        debug: false,
        appId: "wx1b4a19d2537a3306",
        timestamp: Math.floor(Date.now() / 1000),
        nonceStr: Math.random().toString(36).substr(2, 15),
        signature: signature, // 确保 signature 正确
        jsApiList: ["scanQRCode", "chooseImage", "navigateTo"],
      });

      wx.ready(() => {
        console.log("WeChat SDK is ready.");
      });
    },
    handleNavigation() {
      if (typeof wx !== "undefined" && wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: "/pages/Publish/Publish",
        });
      } else {
        console.warn("Not in WeChat Mini Program environment.");
      }
    },

    async onLoad(options) {
      // 贷款详情
      let params = {
        financeProductId: options.financeProductId,
      };
      let resData = await DetailsFinancialProducts(params);
      this.obj = resData;
      // this.propsList = resData.data[0];
      let propsList1 = resData.data[0];
      propsList1.introduction = propsList1.introduction
        ? propsList1.introduction
        : "暂无数据";
      propsList1.serviceUser = propsList1.serviceUser
        ? propsList1.serviceUser
        : "暂无数据";
      // console.log(propsList.serviceUser, '查看有这个数值嘛')
      // propsList.serviceUser = propsList.split('↵').join('\n');
      // 还款方式
      let res = await repayType();
      let TorRepayType = res.data;
      // let propsList = newList
      let arr2 = Array.from(propsList1.repayType.split(","));
      let repay_Type = "";
      for (let i = 0; i < TorRepayType.length; i++) {
        if (arr2.indexOf(TorRepayType[i].value) > -1) {
          repay_Type += TorRepayType[i].description + " ";
        }
      }
      propsList1.repayType = repay_Type;

      //贷款方式
      let params2 = "credit_type";
      let loanData = await repayType(params2);
      let ToGuaranteeMethods = loanData.data;
      let arr = Array.from(propsList1.creditType.split(","));
      let credit_type_name = "";
      for (let i = 0; i < ToGuaranteeMethods.length; i++) {
        if (arr.indexOf(ToGuaranteeMethods[i].value) > -1) {
          credit_type_name += ToGuaranteeMethods[i].description + " ";
        }
      }
      propsList1.creditType = credit_type_name;

      //贷款额度改变样式
      let newMoney = propsList1.minMoney + " ~ " + propsList1.maxMoney;
      propsList1.newMoney = newMoney;
      //改变图片样式
      // propsList1.logoUrl = `${app.globalData.imgurl}/admin/file/get?ossFilePath=${propsList1.logoUrl}`;

      // console.log(propsList, "查看newData");
      // 可贷款金额
      const newMinMoney = propsList1.minMoney / 10000;
      // item.minMoney = newMinMoney;
      const newMaxMoney = propsList1.maxMoney / 10000;
      const priceSection = newMinMoney + "~" + newMaxMoney;
      propsList1.priceSection = priceSection;
      this.propsList = propsList1;
    },
    apply() {
      console.log(this.identityObjList);
      this.$router.push({
        name: "xctfw",
      });
    },
  },
};
</script>
<style lang="less" scoped>
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Source Han Sans CN", sans-serif;
}

div {
  box-sizing: border-box;
}

/* 贷款详细信息 */
.loanDetail {
  // text-align: center;

  // align-items: center;
  width: 100vw;
  // height: 60vh;
  padding: 10px;

}
.box {
  width: 100%;
     background: #ffffff;
  border-radius: 15px;
  padding: 10px;
}
</style>
